import React from 'react'
import styled from 'styled-components'
import luca from './luca.jpg'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

function Banner({setSide}) {
    return (
        <Container >
            <BannerText>
                    <Text>Hello, I'm Serge Metellus</Text>
                    <SubText>Full-Stack Developer</SubText>
            </BannerText>
            <BannerImg>
                
            </BannerImg>
            <Icon>
                    <ArrowDownwardIcon style={{fontSize: '2.5rem'}}/>
                </Icon>
        </Container>
    )
}

export default Banner

const Container = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0);
    height: 100%;
    // width: 400px;
    overflow: hidden;
`

const BannerImg = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
    height: 100%;
    display: block;
  
    background-image: url("${luca}");

`

const BannerText = styled.div`
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4vw;
    position: absolute;
    width: 100%;
   
    @media(min-width: 1200px){
        font-size: 2.5vw;
        
    }
`

const Text = styled.h1`

`

const SubText =styled.h3`

`

const Icon = styled.div`
    top: 65%;
    left: 50%;
    transform: translate(-30%,500%);
    position: absolute;
    cursor: pointer;
    color: white; 
`